import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-cyan-500" smooth to="/#about">
                Hakkımızda
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-cyan-500" smooth to="/#services">
                Hizmetlerimiz
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-cyan-500" to="/">
                Portfolio
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-cyan-500" to="/contact#contact">
                İletişim
            </HashLink>
            <HashLink className="text-white bg-cyan-500 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/contact">
                İletişim Form
            </HashLink>
        </>
    )
}

export default NavLinks;
